import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title, siteUrl }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaSiteUrl = siteUrl || site.siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `favicons/favicon.png`,
        },
        {
          property: `og:url`,
          content: metaSiteUrl,
        },
        {
          property: `og:locale`,
          content: `se`,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `twitter:site`,
          content: metaSiteUrl,
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `favicons/favicon.png`,
        },
        {
          name: `format-detection`,
          content: `telephone=no`,
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: `yes`,
        },
        {
          name: `apple-mobile-web-app-title`,
          content: title,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `black`,
        },
        {
          name: `google`,
          content: `notranslate`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "${site.siteMetadata.title}",
            "description": "${metaDescription}",
            "url": "https://vpaxet.se",
            "logo": "https://vpaxet.se/favicons/favicon.png",
          }
      `}
      </script>
      <link rel="dns-prefetch" href="//vpaxet.se" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `sv-SE`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
