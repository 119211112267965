import React from "react"
import PropTypes from "prop-types"
import Main from "../Main"
import Footer from "../Footer"
import "./Layout.scss"

const Layout = ({ children }) => (
  <>
    <Main>{children}</Main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
