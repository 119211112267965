import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Footer.scss"

const Footer = () => {
  const { axet, vasterport } = useStaticQuery(graphql`
    query {
      axet: file(relativePath: { eq: "axet.png" }) {
        childImageSharp {
          fixed(height: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      vasterport: file(relativePath: { eq: "vasterport.png" }) {
        childImageSharp {
          fixed(height: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <footer id="footer">
      <a
        href="http://www.galleriaaxet.se"
        target="_blank"
        rel="noopener noreferrer"
        title="Galleria Västerport"
        className="icon"
      >
        <Img fixed={axet.childImageSharp.fixed} alt="Galleria Axet" />
      </a>
      <a
        href="http://www.vasterport.com"
        target="_blank"
        rel="noopener noreferrer"
        title="Galleria Västerport"
        className="icon"
      >
        <Img
          fixed={vasterport.childImageSharp.fixed}
          alt="Galleria Västerport"
        />
      </a>
      <p>
        <small>&copy; {new Date().getFullYear()}. Med ensamrätt.</small>
      </p>
    </footer>
  )
}

export default Footer
